:root {
  .mat-typography.cui-app-main {
    --jd-padding-default: var(--cui-small-space);
    --jd-border-radius-default: 0.188rem;
    --jd-icon-loader-size: 3rem;
    --jd-width-element-default: 1.125rem;
    --jd-height-element-default: 1.125rem;
    --jd-width-default: 11.25rem;
    --jd-width-input-min: var(--jd-width-default);
    --jd-input-height: 2rem;
    --jd-button-icon-size: 0.875rem;
    --jd-margin-v: 0.125rem;
    --jd-button-df-size: calc((var(--jd-button-icon-size) - 0.25rem) * 2);
    --jd-button-size: calc(var(--jd-button-icon-size) + var(--jd-button-df-size) + var(--jd-margin-v) * 2);
    --jd-focus-input-box-shadow: 0 0 0 0.05rem rgba(0, 123, 255, 0.25);

    --jd-font-size-default: 0.875rem;
    --jd-font-size-small: 0.588rem;
  }
}

cui-rich-editor .mdc-notched-outline__notch {
  border-top: none;
}

cui-rich-editor {
  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    padding: 0;
    .mat-mdc-form-field-infix {
      padding: 0;
      padding-top: var(--cui-small-space);
    }

    // This style will keep label always floating, no matter if input is empty or not, because there is not other way to make it look pretty.
    .mdc-notched-outline--upgraded label.mdc-floating-label {
      --mat-mdc-form-field-label-transform: translateY(
          calc(calc(6.75px + var(--mat-form-field-container-height) / 2) * -1)
        )
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      transform: var(--mat-mdc-form-field-label-transform);
    }
  }
}
.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
  border-top: none;
}

.rich-text {
  table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    margin-bottom: 1em;
    margin-top: 1em;
    max-width: 100%;
    width: 100%;

    td {
      border: 1px solid var(--jd-color-border);
      min-width: 2em;
      padding: 0.4em;
      -webkit-user-select: text;
      user-select: text;
      vertical-align: middle;
    }
  }
}

.mat-typography.cui-app-main {
  .jodit-toolbar-button {
    height: 2.188rem;
    min-width: 2.125rem;
  }

  .jodit-toolbar-button button {
    appearance: none;
    height: 2.125rem;
    min-width: 2.125rem;
    padding: 0;
  }

  .jodit-dialog__header-title,
  .jodit-dialog__header-toolbar {
    font-family: var(--cui-font-h3-font-family);
    color: var(--cui-font-h3-color);
    font-size: var(--cui-font-h3-size);
    line-height: var(--cui-font-h3-line-height);
    font-weight: var(--cui-font-h3-weight);
  }

  @media (max-width: 480px) {
    .jodit-dialog:not(.jodit-dialog_adaptive_false) .jodit-dialog__header {
      flex-direction: row;
    }
  }

  .jodit-toolbar-collection_size_middle.jodit-toolbar-collection_mode_horizontal,
  .jodit-toolbar-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal,
  .jodit-toolbar-editor-collection_size_middle.jodit-toolbar-collection_mode_horizontal,
  .jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
    background-image: repeating-linear-gradient(transparent 0, transparent 2.313rem, var(--jd-color-border) 2.375rem);
  }

  .jodit-toolbar-collection .jodit-toolbar-button,
  .jodit-toolbar-collection .jodit-toolbar-content,
  .jodit-toolbar-collection .jodit-toolbar-select,
  .jodit-toolbar-editor-collection .jodit-toolbar-button,
  .jodit-toolbar-editor-collection .jodit-toolbar-content,
  .jodit-toolbar-editor-collection .jodit-toolbar-select {
    margin: var(--jd-margin-v) 0.063rem;
    padding: 0;
  }

  .jodit-status-bar {
    height: 1.25rem;
  }

  .jodit-dialog__header {
    border-bottom: 1px solid var(--jd-color-border);
    cursor: move;
    display: flex;
    justify-content: space-between;
    min-height: 2.5rem;
    text-align: left;
  }
}
