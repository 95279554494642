cui-button-dropdown {
  mat-button-toggle.cui-btn-dropdown-cta button {
    min-width: var(--cui-size-buttons-min-width);
    padding: 0 var(--cui-size-buttons-padding-left-right);
    font-family: var(--cui-font-buttons-font-family);
    font-weight: var(--cui-font-buttons-weight);
    font-size: var(--cui-font-buttons-size-large);
    line-height: var(--cui-font-buttons-line-height-large);
    text-transform: var(--cui-buttons-text-transform);
    letter-spacing: var(--cui-font-buttons-letter-spacing-large);
    color: var(--color-white);
    border-right: var(--cui-size-buttons-border-right);
    background-color: var(--cui-color-buttons);
    height: var(--cui-size-buttons-height-large);

    span {
      padding: 0;
    }
  }

  mat-button-toggle.cui-btn-dropdown button {
    background-color: var(--cui-color-buttons);
    color: var(--color-white);
    height: var(--cui-size-buttons-height-large);
  }

  mat-button-toggle.mat-button-toggle-disabled button {
    color: var(--cui-color-buttons-disabled);
    background-color: var(--mdc-protected-button-disabled-container-color);
  }
}
