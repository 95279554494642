@import 'mat-table';
@import 'mat-button-toggle';
@import 'ng-select';
@import 'rich-text-editor';
@import 'mat-button';
@import 'mat-icon';
@import 'mat-datepicker';

span.mat-slide-toggle-bar:hover span.mat-slide-toggle-persistent-ripple {
  opacity: 0;
}
