ng-select.ng-select.ng-select-single > .ng-select-container > .ng-value-container {
  line-height: 32px;

  & > div.ng-input {
    top: 16px;
    left: 13px;
    width: 90%;

    & > input {
      font: inherit;
      color: var(--cui-color-label-text-color);
      letter-spacing: 0.5px;
    }
  }
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--cui-color-label-disabled-text-color);
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--cui-color-label-text-color);
}

.ng-select {
  background-color: inherit;
  padding-bottom: 0;
  .ng-select-container.ng-appearance-outline {
    background-color: inherit;
    padding: 0 0.75rem 0 0.375rem;

    .ng-value-container {
      background-color: inherit;
    }
  }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  background-color: inherit;
  transform: translateY(-1.98125em) scale(0.75) perspective(100px) translateZ(0.001px);
  font-family: var(--cui-font-placeholder-font-family);
  font-size: var(--cui-font-placeholder-size);
  font-weight: var(--cui-font-placeholder-weight);
}

.ng-select:not(.has-selected) .ng-select-container .ng-value-container .ng-placeholder {
  transform: none;
}

.ng-select.required .ng-select-container .ng-value-container .ng-placeholder {
  &::after {
    content: '*';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    padding-left: 2px;
  }
}

.ng-select.has-error,
.ng-select.ng-select-focused.has-error {
  .ng-select-container.ng-appearance-outline {
    .ng-placeholder {
      color: var(--color-danger);
    }

    &::after,
    &:hover::after {
      border: var(--color-danger) solid 2px;
    }
  }
}

.ng-select.ng-select-focused {
  .ng-select-container.ng-appearance-outline {
    .ng-placeholder {
      color: var(--cui-color-primary);
      transform: translateY(-1.98125em) scale(0.75) perspective(100px) translateZ(0.001px);
    }

    &::after,
    &:hover::after {
      border: var(--cui-color-primary) solid 2px;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: normal;
  padding: var(--cui-small-space) var(--cui-small-space);
}

.ng-select.ng-select-disabled .ng-select-container.ng-appearance-outline:hover:after {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.ng-select.ng-select-disabled .ng-select-container:after {
  background-image: none;
}

.ng-dropdown-panel .ng-dropdown-header {
  display: flex;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
  }
}

.cui-app-main.mat-typography {
  ng-select.ng-select.ng-select-single > .ng-select-container > .ng-value-container {
    padding-left: var(--cui-small-space);
    line-height: 1.5rem;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    min-height: 2rem;
  }

  .ng-select {
    &.has-selected {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            transform: translateY(-1.25rem) scale(0.8);
          }
        }
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            transform: translateY(-1.25rem) scale(0.8);
          }
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        &.ng-appearance-outline {
          min-height: 3.25rem;
          .ng-arrow-wrapper {
            bottom: -2px;
            align-self: center;
          }
        }
        .ng-clear-wrapper {
          bottom: -3px;
          align-self: center;
        }

        .ng-value-container {
          .ng-value {
            padding-left: 0;
          }

          .ng-input {
            position: absolute;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
        }
      }
    }
  }
}
