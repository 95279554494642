mat-label {
  font-family: var(--cui-font-placeholder-font-family);
  font-size: var(--cui-font-placeholder-size);
  font-weight: var(--cui-font-placeholder-weight);
  line-height: var(--cui-font-placeholder-line-height);
}

.mat-focused .mat-form-field-required-marker {
  color: var(--cui-color-cta);
}

.cui-app-main {
  mat-expansion-panel.mat-expansion-panel {
    background-color: transparent;
    box-shadow: none;

    .mat-expansion-panel-content {
      div.mat-expansion-panel-body {
        padding-left: var(--cui-large-space);
      }
    }
  }

  .mat-expansion-panel-header {
    padding: 0 var(--cui-small-space);
  }

  .mat-expansion-panel-body {
    padding: 0 var(--cui-medium-space) var(--cui-small-space);
  }
}
