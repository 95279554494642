// This file contains styles to extends material design for our needs without overriding anything.
.cui-app-main,
.cdk-overlay-container {
  // ToDo:Remove after not used anymore
  th.text-right {
    text-align: right;
  }

  // ToDo:Remove after not used anymore
  th.mat-header-cell.text-right {
    text-align: right;
  }

  // ToDo:Remove after not used anymore
  th.text-right .mat-sort-header-button {
    justify-content: flex-end;
    width: 100%;
  }

  // ToDo:Remove after not used anymore
  tbody > tr > td.sortable-col.text-right,
  tfoot > tr > th.sortable-col.text-right {
    padding-right: 34px;
  }

  tr.text-warning > td:first-child {
    border-left: 8px solid var(--color-warning);
    padding-left: 20px;
  }

  .mat-input-element.text-right {
    text-align: right;
  }

  .custom-form-field-size .mat-form-field-infix {
    width: 100%;
  }

  textarea.mat-input-element {
    border: none;
  }

  /** wrap label */
  .mat-checkbox-layout {
    white-space: normal;

    .mat-checkbox-inner-container {
      margin-top: 4px;
    }
  }

  // button inner height
  .mat-mdc-button .mat-mdc-button-touch-target,
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target,
  .mat-mdc-raised-button .mat-mdc-button-touch-target,
  .mat-mdc-outlined-button .mat-mdc-button-touch-target {
    height: var(--mat-form-field-container-height);
  }

  // switch
  .mdc-switch .mdc-switch__ripple {
    height: 20px;
    width: 20px;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 var(--cui-small-space);
    line-height: var(--cui-font-buttons-line-height-small);
    letter-spacing: var(--cui-font-buttons-letter-spacing-large);
  }

  .mat-button-toggle-button {
    height: var(--cui-size-buttons-height-large);
  }

  mat-button-toggle-group mat-button-toggle.mat-button-toggle {
    height: var(--cui-size-buttons-height-large) !important;
  }

  // switch end

  mat-step-header {
    .mat-step-icon {
      mat-icon.mat-icon {
        font-size: 1rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.mat-loading {
  padding-top: 3em;
  padding-bottom: 3em;
  display: flex;
  justify-content: center;
  width: 100%;
}

mat-progress-bar {
  .mat-progress-bar-primary::after {
    background-color: var(--cui-color-progress);
  }
  .mat-progress-bar-buffer {
    background-color: var(--cui-color-progress-buffer);
  }
}

mat-spinner.mat-spinner {
  circle {
    stroke: var(--cui-color-progress);
  }
}

// Disable navigation through material stepper's header
#ocf-cart-checkout-stepper .mat-horizontal-stepper-header-container {
  pointer-events: none;
}

.mat-mdc-outlined-button.mat-accent:not(:disabled) {
  --mdc-outlined-button-outline-color: var(--cui-color-cta);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  --mat-form-field-subscript-text-size: 0.7em;
}

.mat-mdc-raised-button[disabled].mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
  color: #00000042;
}

.mdc-button--outlined .mdc-button__ripple {
  border-color: var(--cui-color-cta);
}

.mat-mdc-checkbox-touch-target {
  height: var(--mdc-checkbox-state-layer-size);
  width: var(--mdc-checkbox-state-layer-size);
}

mat-hint {
  display: flex;
  align-items: center;
  color: var(--color-gray-medium);
}

mat-error.mat-mdc-form-field-error {
  display: flex;
  align-items: center;
}
