:root {
  /* Space and sizing */
  --cui-large-space: 2rem; /* 32px */
  --cui-medium-space: 1rem; /* 16px */
  --cui-small-space: 0.5rem; /* 8px */
  --cui-extra-small-space: 0.25rem; /* 4px */
  --cui-border-radius: 10px;
  --cui-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  /* Font settings */
  --cui-font-family: 'Segoe UI';
  --cui-font-weight-light: 300;
  --cui-font-weight-regular: 400;
  --cui-font-weight-medium: 500;
  --cui-font-weight-semi-bold: 600;
  --cui-font-weight-bold: 700;

  /* Icons */
  --cui-icon-font-size: 1.5rem;
  --cui-icon-width: 1.5rem;
  --cui-icon-height: 1.5rem;

  --cui-small-icon-font-size: 1rem;
  --cui-small-icon-width: 1rem;
  --cui-small-icon-height: 1rem;

  /* Inputs */
  --cui-size-inputs-height-large: 2.375rem; /* 38px */
  --cui-size-inputs-border-radius: 4px;

  /* Buttons */
  --cui-color-buttons: var(--cui-color-cta);
  --cui-color-buttons-active: var(--cui-color-active-button);
  --cui-color-buttons-disabled: var(--cui-color-border);

  --cui-font-buttons-font-family: var(--cui-font-family);
  --cui-font-buttons-weight: var(--cui-font-weight-medium);
  --cui-font-buttons-background: transparent;
  --cui-font-buttons-size-large: 0.875rem; /* 14px */
  --cui-font-buttons-line-height-large: 0.875rem; /* 14px */
  --cui-font-buttons-letter-spacing-large: 0.078125rem; /* 1.25px */
  --cui-size-buttons-height-large: 2.375rem; /* 38px */

  --cui-font-buttons-size-small: 0.875rem; /* 14px */
  --cui-font-buttons-line-height-small: 0.875rem; /* 14px */
  --cui-font-buttons-letter-spacing-small: 0.0625rem; /* 1px */
  --cui-size-buttons-height-small: 1.875rem; /* 30px */

  --cui-buttons-text-transform: none;
  --cui-size-buttons-min-width: 4.375rem; /* 70px */
  --cui-size-buttons-padding-left-right: 1.25rem; /* 20px */
  --cui-size-buttons-border-radius: 4px;
  --cui-size-buttons-no-space-around: 0;

  /* Headings */
  --cui-font-h1-size: 1.875rem; /* 30px */
  --cui-font-h1-line-height: 2.4375rem; /* 39px */
  --cui-font-h1-weight: var(--cui-font-weight-bold);
  --cui-font-h1-font-family: var(--cui-font-family);
  --cui-font-h1-color: var(--cui-color-titles);

  --cui-font-h2-size: 1.375rem; /* 22px */
  --cui-font-h2-line-height: 2rem; /* 32px */
  --cui-font-h2-weight: var(--cui-font-weight-semi-bold);
  --cui-font-h2-font-family: var(--cui-font-family);
  --cui-font-h2-color: var(--cui-color-titles);

  --cui-font-h3-size: 1.125rem; /* 18px */
  --cui-font-h3-line-height: 1.438rem; /* 22px */
  --cui-font-h3-weight: var(--cui-font-weight-medium);
  --cui-font-h3-font-family: var(--cui-font-family);
  --cui-font-h3-color: var(--cui-color-titles);

  --cui-font-h4-size: 1rem; /* 16px */
  --cui-font-h4-line-height: 1.3125rem; /* 21px */
  --cui-font-h4-weight: var(--cui-font-weight-regular);
  --cui-font-h4-font-family: var(--cui-font-family);
  --cui-font-h4-color: var(--cui-color-breadcrumbs);

  --cui-font-h5-size: 0.875rem; /* 14px */
  --cui-font-h5-line-height: 1.188rem; /* 19px */
  --cui-font-h5-weight: var(--cui-font-weight-regular);
  --cui-font-h5-font-family: var(--cui-font-family);
  --cui-font-h5-color: var(--cui-color-breadcrumbs);

  --cui-font-h6-size: 0.875rem; /* 14px */
  --cui-font-h6-line-height: 1.25rem; /* 20px */
  --cui-font-h6-weight: var(--cui-font-weight-light);
  --cui-font-h6-font-family: var(--cui-font-family);
  --cui-font-h6-color: var(--cui-color-body-text);

  /* Paragraphs and text */
  --cui-font-p-size: 0.875rem; /* 14px */
  --cui-font-p-line-height: 1.25rem; /* 20px */
  --cui-font-p-weight: var(--cui-font-weight-regular);
  --cui-font-p-font-family: var(--cui-font-family);
  --cui-font-p-color: var(--cui-color-breadcrumbs);

  --cui-font-small-size: 0.6875rem; /* 11px */
  --cui-font-small-line-height: 0.8rem;
  --cui-font-small-weight: var(--cui-font-weight-regular);
  --cui-font-small-font-family: var(--cui-font-family);
  --cui-font-small-color: var(--cui-color-breadcrumbs);

  --cui-font-placeholder-size: 0.75rem; /* 12px */
  --cui-font-placeholder-line-height: 1rem; /* 16px */
  --cui-font-placeholder-weight: var(--cui-font-weight-light);
  --cui-font-placeholder-font-family: var(--cui-font-family);
  --cui-font-placeholder-font-color: var(--cui-color-mat-input-placeholder);

  --cui-font-link-size: 0.875rem; /* 14px */
  --cui-font-link-line-height: 1.1875rem; /* 19px */
  --cui-font-link-weight: var(--cui-font-weight-regular);
  --cui-font-link-font-family: var(--cui-font-family);
  --cui-font-link-color: var(--cui-color-links);
}

@mixin typography($size) {
  font-family: var(--cui-font-#{$size}-font-family);
  color: var(--cui-font-#{$size}-color);
  font-size: var(--cui-font-#{$size}-size);
  line-height: var(--cui-font-#{$size}-line-height);
  font-weight: var(--cui-font-#{$size}-weight);
}

.mat-typography.cui-app-main {
  h1 {
    @include typography('h1');
  }
  h2 {
    @include typography('h2');
  }
  h3 {
    @include typography('h3');
  }
  h4 {
    @include typography('h4');
  }
  h5 {
    @include typography('h5');
  }
  h6 {
    @include typography('h6');
  }
  p {
    @include typography('p');
    margin-top: var(--cui-small-space);
    margin-bottom: var(--cui-small-space);
  }
  small {
    @include typography('small');
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    margin: 0;
  }

  .text-muted div,
  .text-muted p,
  .text-muted i {
    color: var(--color-gray-light);
  }

  .alert:empty {
    display: none;
  }

  .color-success {
    color: var(--color-success);
  }

  .color-danger {
    color: var(--color-danger);
  }
}

body {
  font-family: var(--cui-font-family);
}
