.hidden {
  opacity: 0;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

label,
span,
h2,
h3,
h4,
h5,
p {
  a:-webkit-any-link {
    color: var(--cui-color-links);
    cursor: pointer;
    text-decoration: none;
  }
}
