@use '@angular/material' as mat;
@use 'cui-components/styles/styles.scss';
@use 'cui-components/styles/configs/layout.scss' as layout;

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (max-width: 600px) {
  html {
    font-size: 13px; /* Smaller base font size for mobile */
  }
}

.mat-typography.cui-app-main {
  cui-app-container {
    background-color: var(--cui-color-page-bg);
  }

  cui-form-slide-toggle {
    margin: var(--cui-extra-small-space) 0;
  }

  mat-icon.mat-icon.extra-small-icon {
    font-size: var(--cui-small-icon-font-size);
    width: var(--cui-small-icon-width);
    height: var(--cui-small-icon-height);
  }

  .ag-theme-material .ag-standard-button:disabled {
    color: var(--ag-disabled-foreground-color);
    background-color: var(--ag-input-disabled-background-color);
    border-color: var(--ag-input-disabled-border-color);
  }
}

.mat-typography.cui-app-main li {
  font-family: var(--cui-font-p-font-family);
  color: var(--cui-font-p-color);
  font-size: var(--cui-font-p-size);
  line-height: var(--cui-font-p-line-height);
  font-weight: var(--cui-font-weight-regular);
}

// This is a fix for ag-grid's issue, where showing vertical scrollbar triggers horizontal one.
.ag-layout-normal > .ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}

.custom-modal-container .mat-dialog-container {
  padding: 0;
}

.cui-app-main {
  mat-expansion-panel.mat-expansion-panel {
    background-color: transparent;
    box-shadow: none;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 40px;
  }

  .mat-expansion-panel-header {
    padding: 0 var(--cui-small-space);
  }
}

cui-list.no-wrap {
  cui-list-item {
    div.item-text {
      word-break: normal;
    }
  }
}

cui-form-slide-toggle.space-between {
  label {
    justify-content: space-between;
  }
}

.flex-between {
  width: 100%;

  @include layout.horizontal-space-between();
}

cdk-dialog-container:focus-visible {
  outline: none;
}

.mce-content-body {
  padding: var(--cui-small-space) var(--cui-large-space) var(--cui-large-space) var(--cui-small-space);
}

cui-menu.action-menu {
  @include layout.horizontal-block-3rdw();
  & {
    max-width: 20rem;
    min-width: 20rem;
    overflow-y: auto;
  }
}

hui-comment-details {
  .comment-header-info {
    flex-direction: column;
  }
}

ng-select.ng-select.required div.ng-select-container div.ng-value-container div.ng-placeholder::after {
  font-size: 1.25rem;
}
