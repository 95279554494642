:root {
  --color-accent: #fe7b00;
  --color-primary: #0082ff;
  --color-warning: #fdc02f;
  --color-warning-bg: #ffefc9;
  --color-success: #128464;
  --color-danger: #ef3e36;
  --color-danger-bg: #fac5be;
  --color-info: #122b60;
  --color-gray-very-dark: #2a2a2a;
  --color-gray-dark: #424242;
  --color-gray-medium: #7f7f7f;
  --color-gray-light: #bebebe;
  --color-gray-very-light: #e0e0e0;
  --color-white: #f8fafc;
  --color-overlay: rgba(128, 128, 128, 0.1);
  --color-disabled: rgba(0, 0, 0, 0.38);

  --cui-color-card-bg: #fbfbfb;
  --cui-color-primary: #0082ff;
  --cui-color-page-bg: #f8f8f8;
  --cui-color-hover: #dce7ff9c;
  --cui-color-active: #dce7ff;
  --cui-color-border: #bcbec0;
  --cui-color-body-text: #757575;
  --cui-color-breadcrumbs: #58595b;
  --cui-color-titles: #46403c;
  --cui-color-icon: #000000;
  --cui-color-progress: #8bd908;
  --cui-color-progress-buffer: #d7ebb6;
  --cui-color-links: #0082ff;
  --cui-color-cta: #e46f00;
  --cui-color-active-button: #e56700;
  --cui-color-attention: #ef3e36;
  --cui-color-additional-items-2: #214fb2;

  --cui-color-rich-text-selected: rgba(254, 123, 0, 0.4);
  --cui-color-rich-text-hover: rgba(254, 123, 0, 0.2);
  --cui-color-table-resize-handle: #00000021;
  --cui-color-input-outline-color: #0000002b;
  --cui-color-label-text-color: var(--cui-color-titles);
  --cui-color-label-hover-text-color: rgb(16 14 14);
  --cui-color-label-disabled-text-color: rgb(0 0 0 / 50%);

  --cui-color-mat-input-placeholder: #100e0eb8;
}

:root {
  .jodit-toolbar-button {
    --jd-color-button-background-hover-opacity40: var(--cui-color-rich-text-selected);
    --jd-color-button-background-hover: var(--cui-color-rich-text-hover);
  }
}
