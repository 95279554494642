.cui-app-main.mat-typography {
  mat-form-field {
    font-family: var(--cui-font-family);
    .mat-mdc-form-field-icon-suffix {
      display: flex;
      align-items: center;
      padding: 0 var(--cui-small-space) 0 0;
    }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
      color: var(--cui-color-label-disabled-text-color);
    }
    .mdc-text-field__input {
      color: var(--cui-color-label-text-color);
    }
  }

  // all buttons touch target
  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: var(--cui-icon-height);
    width: var(--cui-icon-width);
  }

  // icon button
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  cdk-nested-tree-node cdk-nested-tree-node cui-icon .mat-icon {
    height: calc(var(--cui-icon-height) - var(--cui-extra-small-space));
    width: calc(var(--cui-icon-width) - var(--cui--extra-small-space));
    font-size: calc(var(--cui-icon-font-size) - var(--cui-extra-small-space));
  }
}

.mat-mdc-form-field-icon-prefix {
  mat-icon {
    margin-left: 0.313rem;
  }
}
