.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: var(--cui-color-cta);
  span.mat-button-toggle-label-content {
    color: var(--cui-color-card-bg);
  }
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--cui-color-cta);
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px var(--cui-color-cta);
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-appearance-standard.mat-button-toggle-checked) {
  color: var(--cui-color-cta);
  background: var(--cui-color-card-bg);
}
