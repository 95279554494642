.mat-table {
  //#region Resets and global styles
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  //#endregion

  //#region Right-alignment for numeric columns
  .mat-numeric {
    text-align: right;
  }

  td.mat-sort-numeric {
    padding-right: 24px;
    text-align: right;
  }

  th.mat-sort-numeric > .mat-sort-header-container {
    justify-content: flex-end;
  }

  //#endregion
}
