$bp-xs: 600px;
$bp-sm: 960px;
$bp-md: 1280px;
$bp-lg: 1920px;
$bp-xl: 5000px;

$bp-cui-xs: 600px;
$bp-cui-sm: 960px;
$bp-cui-md: 1280px;
$bp-cui-lg: 1920px;
$bp-cui-xl: 5000px;

//#region Exact screen size media queries
@mixin media-xs {
  @media screen and (max-width: ($bp-xs - 1px)) {
    @content;
  }
}

@mixin media-sm {
  @media screen and (min-width: $bp-xs) and (max-width: ($bp-sm - 1px)) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: $bp-sm) and (max-width: ($bp-md - 1px)) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: $bp-md) and (max-width: ($bp-lg - 1px)) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: $bp-lg) and (max-width: ($bp-xl - 1px)) {
    @content;
  }
}

//#endregion

//#region Less-than media queries
@mixin media-lt-xs {
  @media screen and (max-width: $bp-xs - 1px) {
    @content;
  }
}

@mixin media-lt-sm {
  @media screen and (max-width: $bp-sm - 1px) {
    @content;
  }
}

@mixin media-lt-md {
  @media screen and (max-width: $bp-md - 1px) {
    @content;
  }
}

@mixin media-lt-lg {
  @media screen and (max-width: $bp-lg - 1px) {
    @content;
  }
}

@mixin media-lt-xl {
  @media screen and (max-width: $bp-xl - 1px) {
    @content;
  }
}
//#endregion

//#region Greater-than media queries
@mixin media-gt-xs {
  @media screen and (min-width: $bp-xs) {
    @content;
  }
}
@mixin media-gt-sm {
  @media creen and (min-width: $bp-sm) {
    @content;
  }
}
@mixin media-gt-md {
  @media screen and (min-width: $bp-md) {
    @content;
  }
}
@mixin media-gt-lg {
  @media screen and (min-width: $bp-lg) {
    @content;
  }
}
//#endregion

//#region Device-based media queries

@mixin media-phone {
  @media screen and (max-width: ($bp-sm - 1)) {
    @content;
  }
}
@mixin media-tablet {
  @media screen and (min-width: $bp-xs) and (max-width: ($bp-md - 1)) {
    @content;
  }
}
@mixin media-desktop {
  @media screen and (min-width: ($bp-md)) {
    @content;
  }
}
//#endregion

//#region highlander screen size media queries
@mixin media-cui-xs {
  @media screen and (max-width: ($bp-cui-xs - 1px)) {
    @content;
  }
}

@mixin media-cui-sm {
  @media screen and (min-width: $bp-cui-xs) and (max-width: ($bp-cui-sm - 1px)) {
    @content;
  }
}

@mixin media-cui-md {
  @media screen and (min-width: $bp-cui-sm) and (max-width: ($bp-cui-md - 1px)) {
    @content;
  }
}

@mixin media-cui-lg {
  @media screen and (min-width: $bp-cui-md) and (max-width: ($bp-cui-lg - 1px)) {
    @content;
  }
}

@mixin media-cui-xl {
  @media screen and (min-width: $bp-cui-lg) {
    @content;
  }
}

//#endregion

//#region highlander screen Less-than media queries

@mixin media-cui-lt-xs {
  @media screen and (max-width: $bp-cui-xs - 1px) {
    @content;
  }
}

@mixin media-cui-lt-sm {
  @media screen and (max-width: $bp-cui-sm - 1px) {
    @content;
  }
}

@mixin media-cui-lt-md {
  @media screen and (max-width: $bp-cui-md - 1px) {
    @content;
  }
}

@mixin media-cui-lt-lg {
  @media screen and (max-width: $bp-cui-lg - 1px) {
    @content;
  }
}

@mixin media-cui-lt-xl {
  @media screen and (max-width: $bp-cui-xl - 1px) {
    @content;
  }
}
//#endregion

//#region highlander screen Greater-than media queries

@mixin media-cui-gt-xs {
  @media screen and (min-width: $bp-cui-xs) {
    @content;
  }
}
@mixin media-cui-gt-sm {
  @media creen and (min-width: $bp-cui-sm) {
    @content;
  }
}
@mixin media-cui-gt-md {
  @media screen and (min-width: $bp-cui-md) {
    @content;
  }
}
@mixin media-cui-gt-lg {
  @media screen and (min-width: $bp-cui-lg) {
    @content;
  }
}
@mixin media-cui-gt-xl {
  @media screen and (min-width: $bp-cui-xl) {
    @content;
  }
}
//#endregion
