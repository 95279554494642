.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--cui-color-card-bg);
  height: 100%;
  max-width: 18.75rem;
  max-height: 100vh;
  overflow: hidden;
}

mat-drawer.mat-drawer {
  transform: translate3d(-80%, 0, 0);
  display: block;
  visibility: visible;
}

.mat-toolbar span {
  font: var(--cui-font-p-weight) var(--cui-font-p-size) / var(--cui-font-p-line-height) var(--cui-font-p-font-family);
}

cui-side-nav-group.padding-left {
  padding-left: var(--cui-small-space);
}

cdk-nested-tree-node {
  cdk-nested-tree-node {
    cui-icon {
      .mat-icon {
        color: var(--cui-color-breadcrumbs);
      }
    }
  }
}
